import { NavLink } from 'react-router-dom'
import styles from './Bonus.module.scss'
import close from '../../../assets/images/icons/close-btn.svg';
import { useTranslation } from 'react-i18next';

const Bonus = ({ closeForm, onRegisterClick }) => {
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeForm()
    }
  }

  const { t } = useTranslation("bonus_reg_rules");

  return (
    <div className={styles.wrapper}
      onClick={handleOutsideClick}>
      <div className={styles.container}>
        <div className={styles.closeBtn}
          onClick={onRegisterClick}>
          <img src={close} alt="" />
        </div>
        <div className={styles.title}>
          {t('header')}
        </div>
        <div className={styles.offer}>
          <p className={styles.justify}><strong>{t('justify')}</strong></p>
          <ul>
            <li>{t('li_1')}</li>
            <li>{t('li_2')}</li>
            <li>{t('li_3')}</li>
            <li>{t('li_4')}</li>
          </ul>
          <br />
          <p><b> {t('header_2')}</b></p>

          <ul>
            <li>{t('li_5')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Bonus