import React, { useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import arrowLeft from "../../../assets/images/icons/tournaments-arrow-left.svg";
import arrowRight from "../../../assets/images/icons/tournaments-arrow-right.svg";
import dota from "../../../assets/images/dota.svg";
import cs from "../../../assets/images/cs.svg";
import LoL from "../../../assets/images/lol-icon.svg";
import calendar from "../../../assets/images/calendar.svg";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SPREADSHEET_ID = "1G8zbSbpvlUYmK04TfiF0Bjjgj013dxyMdstZLF0WBQY";
const SHEET_ID = "Лист1";
const API_KEY = "AIzaSyCJ-OwS_CHE1aIcz4osT4bPa1Q6Mc2AolE";

const eventColors = [
  {
    backgroundColor: "#5A5A5A",
    color: "#FFFFFF",
  },
  {
    backgroundColor: "#333217",
    color: "#FFEB31",
  },
  {
    backgroundColor: "#3A2159",
    color: "#AE6FFF",
  },
  {
    backgroundColor: "#11362C",
    color: "#07EF9C",
  },
  {
    backgroundColor: "#19334F",
    color: "#1D92FF",
  },
];

const Calendar = () => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const [events, setEvents] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  const [filteredGameType, setFilteredGameType] = useState(null);

  const { t } = useTranslation("main");

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const daysOfWeek = [
    t("mo"),
    t("tw"),
    t("we"),
    t("thu"),
    t("fr"),
    t("st"),
    t("sd"),
  ];

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const getFirstDayOfMonth = (month, year) => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1;
  };

  const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);

  const handlePrevMonth = () => {
    setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1));
    if (currentMonth === 0) {
      setCurrentYear((prev) => prev - 1);
    }
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1));
    if (currentMonth === 11) {
      setCurrentYear((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_ID}?alt=json&key=${API_KEY}`;

      try {
        const response = await fetch(url);
        const result = await response.json();

        if (!result.values || result.values.length === 0) {
          console.error("Ошибка: Пустые данные из Google Sheets");
          return;
        }

        result.values.shift();

        const parsedEvents = result.values.map((event, index) => {
          const [dayStart, monthStart, yearStart] =
            event[0]?.trim().split(".") || [];
          const [dayEnd, monthEnd, yearEnd] = event[1]?.trim().split(".") || [];

          const startDate =
            dayStart && monthStart && yearStart
              ? new Date(`${yearStart}-${monthStart}-${dayStart}`)
              : null;

          const endDate =
            dayEnd && monthEnd && yearEnd
              ? new Date(`${yearEnd}-${monthEnd}-${dayEnd}`)
              : null;

          if (!startDate || isNaN(startDate.getTime())) {
            console.warn(`Некорректная дата начала: ${event[0]}`);
          }

          if (!endDate || isNaN(endDate.getTime())) {
            console.warn(`Некорректная дата окончания: ${event[1]}`);
          }

          return {
            startDate:
              startDate && !isNaN(startDate.getTime()) ? startDate : null,
            endDate: endDate && !isNaN(endDate.getTime()) ? endDate : null,
            name: event[2]?.trim() || "Без названия",
            gameType: event[3]?.trim() || "Не указан",
            prize: event[4]?.trim() || "Не указано",
            backgroundColor:
              eventColors[index % eventColors.length].backgroundColor,
            color: eventColors[index % eventColors.length].color,
            description: event[5]?.trim() || "",
            img: event[6]?.trim() || "",
            url: event[7]?.trim() || "#",
          };
        });

        setEvents(parsedEvents);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  const renderDaysOfWeek = () => {
    return daysOfWeek.map((day, index) => (
      <div key={index} className={styles.dayOfWeek}>
        <div className={styles.dayOfWeekWrapper}>{day}</div>
      </div>
    ));
  };

  const renderDays = () => {
    const totalDays = daysInMonth(currentMonth, currentYear);
    const daysArray = [];

    const todayDate = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    for (let i = 0; i < firstDayOfMonth; i++) {
      daysArray.push(
        <div key={`empty-${i}`} className={styles.dayEmpty}></div>
      );
    }

    for (let day = 1; day <= totalDays; day++) {
      const currentDate = new Date(Date.UTC(currentYear, currentMonth, day));

      const isToday =
        day === todayDate &&
        currentMonth === todayMonth &&
        currentYear === todayYear;

      const eventsForDay = events.filter((event) => {
        if (!event.startDate || !event.endDate) return false;

        const start = new Date(
          Date.UTC(
            event.startDate.getUTCFullYear(),
            event.startDate.getUTCMonth(),
            event.startDate.getUTCDate()
          )
        );
        const end = new Date(
          Date.UTC(
            event.endDate.getUTCFullYear(),
            event.endDate.getUTCMonth(),
            event.endDate.getUTCDate()
          )
        );

        const isWithinDateRange = currentDate >= start && currentDate <= end;

        if (filteredGameType) {
          return isWithinDateRange && event.gameType === filteredGameType;
        }

        return isWithinDateRange;
      });

      daysArray.push(
        <div
          key={day}
          className={`${styles.day} ${isToday ? styles.today : ""}`}
        >
          <span className={styles.dayNum}>{day}</span>
          {eventsForDay.map((event, index) => (
            <div
              key={index}
              className={styles.event}
              style={{
                backgroundColor: event.backgroundColor,
                color: event.color,
              }}
              onClick={() => {
                setSelectedEventIndex(events.indexOf(event));
                setIsModalOpen(true);
              }}
            >
              <div className={styles.eventName}>{event.name}</div>
            </div>
          ))}
          <div className={styles.game}>
            {eventsForDay.some((event) => event.gameType === "Dota 2") && (
              <div className={styles.gameItem}>
                <img src={dota} alt="Dota 2" />
              </div>
            )}
            {eventsForDay.some((event) => event.gameType === "CS 2") && (
              <div className={`${styles.gameItem} ${styles.gameItemCS}`}>
                <img src={cs} alt="CS 2" />
              </div>
            )}
            {eventsForDay.some((event) => event.gameType === "LoL") && (
              <div className={`${styles.gameItem} ${styles.gameItemLol}`}>
                <img src={LoL} alt="LoL" />
              </div>
            )}
          </div>
        </div>
      );
    }

    return daysArray;
  };

  const formatEventDate = (startDate, endDate) => {
    if (!startDate || !endDate) return "Дата не указана";

    const formatDate = (date) => {
      if (!date || isNaN(date.getTime())) return "??.??";
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      return `${day}.${month}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    return startDate.getMonth() === endDate.getMonth()
      ? `${formattedStartDate}-${formattedEndDate.split(".")[0]}.${
          formattedEndDate.split(".")[1]
        }`
      : `${formattedStartDate}-${formattedEndDate}`;
  };

  const handleFilterClick = (gameType) => {
    setFilteredGameType((prev) => (prev === gameType ? null : gameType));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.emptyDiv} />
          <div className={styles.month}>
            <div className={styles.arrowWrapper} onClick={handlePrevMonth}>
              <img src={arrowLeft} alt="" />
            </div>
            <div className={styles.monthTxt}>
              {months[currentMonth]} {currentYear}
            </div>
            <div className={styles.arrowWrapper} onClick={handleNextMonth}>
              <img src={arrowRight} alt="" />
            </div>
          </div>

          <div className={styles.filter}>
            <div
              className={`${styles.filterWrapper} ${
                filteredGameType === "Dota 2" ? styles.activeFilter : ""
              }`}
              onClick={() => handleFilterClick("Dota 2")}
            >
              Dota 2
            </div>
            <div
              className={`${styles.filterWrapper} ${
                filteredGameType === "CS 2" ? styles.activeFilter : ""
              }`}
              onClick={() => handleFilterClick("CS 2")}
            >
              CS 2
            </div>
            <div
              className={`${styles.filterWrapper} ${
                filteredGameType === "LoL" ? styles.activeFilter : ""
              }`}
              onClick={() => handleFilterClick("LoL")}
            >
              LoL
            </div>
          </div>

          <div className={styles.emptyDiv} />
        </div>

        <div className={styles.grid}>
          {renderDaysOfWeek()}
          {renderDays()}
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalWrapper}>
            {events[selectedEventIndex].img && (
              <div
                className={styles.modalImg}
                style={{
                  backgroundImage: `url(${events[selectedEventIndex].img})`,
                }}
              />
            )}
            <div className={styles.modalInfo}>
              <div className={styles.modalTitle}>
                Турнир {events[selectedEventIndex].name}
              </div>
              <div className={styles.modalGame}>
                {events[selectedEventIndex].gameType === "Dota 2" ? (
                  <div className={styles.modalGameItem}>
                    <div>
                      <img src={dota} alt="Dota 2" />
                    </div>
                    <div>Dota 2</div>
                  </div>
                ) : events[selectedEventIndex].gameType === "CS 2" ? (
                  <div
                    className={`${styles.modalGameItem} ${styles.modalGameItemCS}`}
                  >
                    <div>
                      <img src={cs} alt="CS 2" />
                    </div>
                    <div>CS 2</div>
                  </div>
                ) : events[selectedEventIndex].gameType === "LoL" ? (
                  <div
                    className={`${styles.modalGameItem} ${styles.modalGameItemLol}`}
                  >
                    <div>
                      <img src={LoL} alt="League of Legends" />
                    </div>
                    <div>League of Legends</div>
                  </div>
                ) : null}

                <div className={styles.modalCalendar}>
                  <div>
                    <img src={calendar} alt="" />
                  </div>
                  <div>
                    {formatEventDate(
                      events[selectedEventIndex].startDate,
                      events[selectedEventIndex].endDate
                    )}
                  </div>
                </div>
              </div>

              <div
                className={styles.modalDescription}
                dangerouslySetInnerHTML={{
                  __html: events[selectedEventIndex].description,
                }}
              />

              <div className={styles.modalBtns}>
                <div
                  onClick={() => {
                    setSelectedEventIndex(null);
                    setIsModalOpen(false);
                  }}
                >
                  <Button color="brown" title="Назад" />
                </div>
                <Link to={`/tournament/${selectedEventIndex}`}>
                  <Button title="Перейти к турниру" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
